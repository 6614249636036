"use client";

import React, { useEffect, useState } from "react";
import LinkItem from "./LinkItem";
import { useMainLayout } from "@/app/context/mainLayoutProvider";
import { cn } from "@/lib/utils";
import { useSession } from "next-auth/react";
import { useGlobalStore } from "@/app/context/globalStoreProvider";
import { useUI } from "@/app/context/UIProvider";

type Props = {
  logoSrcLinkPath: string;
};

const LeftContainer: React.FC<Props> = ({ logoSrcLinkPath }) => {
  const { renderMiddleContent, logoOnlyInheader: _logoOnlyInheader } =
    useMainLayout();
  const [logoOnlyInheader, setLogoOnlyInHeader] = useState(false);
  const [collapseMenu, setCollapseMenu] = useState(false);
  const session = useSession();
  const { isEventPage } = useGlobalStore();

  const { isLessThanMd } = useUI();

  useEffect(() => {
    setLogoOnlyInHeader?.(
      _logoOnlyInheader
        ? true
        : Boolean(session?.data?.user.id) && collapseMenu && isLessThanMd
    );
  }, [collapseMenu, isLessThanMd, session?.data?.user.id, _logoOnlyInheader]);

  useEffect(() => {
    if (Boolean(renderMiddleContent)) {
      setCollapseMenu(true);
    } else {
      setCollapseMenu(false);
    }
  }, [renderMiddleContent]);

  return (
    <div className="flex flex-col items-start gap-1 md:flex-row md:items-center md:gap-6">
      <div
        className={cn(
          "flex w-fit items-center gap-2"
          // !logoOnlyInheader ? "min-w-fit" : "w-full"
        )}
      >
        {isEventPage ? (
          <img
            src={
              logoOnlyInheader
                ? "/assets/logos/gondola.svg"
                : "/assets/logos/gondola-logo-text.svg"
            }
            alt="gondola-logo"
            className={cn(
              logoOnlyInheader
                ? "h-[40px] min-h-[40px] w-[40px] min-w-[40px]"
                : "h-[33px] w-full min-w-[100px] sm:min-w-[200px] md:h-[40px]"
            )}
          />
        ) : (
          <LinkItem
            path={logoSrcLinkPath}
            className={
              logoOnlyInheader
                ? "w-[40px]"
                : "w-full min-w-[100px] sm:min-w-[200px]"
            }
          >
            <img
              src={
                logoOnlyInheader
                  ? "/assets/logos/gondola.svg"
                  : "/assets/logos/gondola-logo-text.svg"
              }
              alt="gondola-logo"
              className={cn(
                logoOnlyInheader
                  ? "h-[40px] min-h-[40px] w-[40px] min-w-[40px]"
                  : "h-[33px] w-full min-w-[100px] sm:min-w-[200px] md:h-[40px]"
              )}
            />
          </LinkItem>
        )}
      </div>
    </div>
  );
};

export default LeftContainer;
