import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/gondola-web-app/app/context/clientProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/gondola-web-app/app/context/globalStoreProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/gondola-web-app/app/context/headerProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/gondola-web-app/app/context/mainLayoutProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["PHProvider"] */ "/vercel/path0/gondola-web-app/app/context/postHogProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/gondola-web-app/app/context/UIProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/gondola-web-app/components/layout/analytics/EventTracker.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/gondola-web-app/components/layout/analytics/PostHogPageView.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/gondola-web-app/components/layout/footer/FooterWrapper.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/gondola-web-app/components/layout/header/HeaderWrapper.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/gondola-web-app/components/layout/header/LeftContainer.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/gondola-web-app/components/layout/header/MiddleComponent.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/gondola-web-app/components/layout/header/RightContainer.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/gondola-web-app/components/modal/adminModal/AdminModal.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/gondola-web-app/components/modal/adminModal/AdminModalTrigger.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/gondola-web-app/components/ui/errorBoundaryWrapper/ErrorBoundaryWrapper.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Analytics"] */ "/vercel/path0/gondola-web-app/node_modules/@vercel/analytics/dist/react/index.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["SpeedInsights"] */ "/vercel/path0/gondola-web-app/node_modules/@vercel/speed-insights/dist/next/index.mjs");
;
import(/* webpackMode: "eager" */ "/vercel/path0/gondola-web-app/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/gondola-web-app/node_modules/next/font/local/target.css?{\"path\":\"app/layout.tsx\",\"import\":\"\",\"arguments\":[{\"src\":[{\"path\":\"../public/fonts/SuisseIntl-ThinItalic.otf\",\"weight\":\"100\",\"style\":\"italic\"},{\"path\":\"../public/fonts/SuisseIntl-Black.otf\",\"weight\":\"900\",\"style\":\"normal\"},{\"path\":\"../public/fonts/SuisseIntl-BlackItalic.otf\",\"weight\":\"900\",\"style\":\"italic\"},{\"path\":\"../public/fonts/SuisseIntl-Bold.otf\",\"weight\":\"700\",\"style\":\"normal\"},{\"path\":\"../public/fonts/SuisseIntl-BoldItalic.otf\",\"weight\":\"700\",\"style\":\"italic\"},{\"path\":\"../public/fonts/SuisseIntl-Book.otf\",\"weight\":\"400\",\"style\":\"normal\"},{\"path\":\"../public/fonts/SuisseIntl-BookItalic.otf\",\"weight\":\"400\",\"style\":\"italic\"},{\"path\":\"../public/fonts/SuisseIntl-Light.otf\",\"weight\":\"300\",\"style\":\"normal\"},{\"path\":\"../public/fonts/SuisseIntl-LightItalic.otf\",\"weight\":\"300\",\"style\":\"italic\"},{\"path\":\"../public/fonts/SuisseIntl-Medium.otf\",\"weight\":\"500\",\"style\":\"normal\"},{\"path\":\"../public/fonts/SuisseIntl-MediumItalic.otf\",\"weight\":\"500\",\"style\":\"italic\"},{\"path\":\"../public/fonts/SuisseIntl-Regular.otf\",\"weight\":\"400\",\"style\":\"normal\"},{\"path\":\"../public/fonts/SuisseIntl-RegularItalic.otf\",\"weight\":\"400\",\"style\":\"italic\"},{\"path\":\"../public/fonts/SuisseIntl-SemiBold.otf\",\"weight\":\"600\",\"style\":\"normal\"},{\"path\":\"../public/fonts/SuisseIntl-SemiBoldItalic.otf\",\"weight\":\"600\",\"style\":\"italic\"},{\"path\":\"../public/fonts/SuisseIntl-Thin.otf\",\"weight\":\"100\",\"style\":\"normal\"},{\"path\":\"../public/fonts/SuisseIntl-Ultralight.otf\",\"weight\":\"200\",\"style\":\"normal\"},{\"path\":\"../public/fonts/SuisseIntl-UltralightItalic.otf\",\"weight\":\"200\",\"style\":\"italic\"}],\"display\":\"swap\",\"fallback\":[\"sans-serif\"]}],\"variableName\":\"suisseIntlFonts\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/gondola-web-app/node_modules/next/font/local/target.css?{\"path\":\"lib/utils.tsx\",\"import\":\"\",\"arguments\":[{\"src\":[{\"path\":\"../public/fonts/PPEiko-Medium.otf\",\"weight\":\"500\",\"style\":\"normal\"},{\"path\":\"../public/fonts/PPEiko-MediumItalic.otf\",\"weight\":\"500\",\"style\":\"italic\"}]}],\"variableName\":\"ppeikoFont\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/gondola-web-app/node_modules/react-toastify/dist/react-toastify.esm.mjs");
;
import(/* webpackMode: "eager" */ "/vercel/path0/gondola-web-app/styles/globals.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/gondola-web-app/node_modules/react-toastify/dist/ReactToastify.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/gondola-web-app/styles/reactToastify.css");
