"use client";

import { usePathname, useSearchParams } from "next/navigation";
import { useCallback, useEffect } from "react";
import { useGlobalStore } from "@/app/context/globalStoreProvider";

const EventTracker = () => {
  const pathname = usePathname();
  const searchParams = useSearchParams();
  const { user } = useGlobalStore();

  const trackEvent = useCallback(async () => {
    if (process.env.NEXT_PUBLIC_ENVIRONMENT !== "production") {
      return;
    }
    const fbclid = searchParams.get("fbclid");
    if (user?.data.optOut !== true) {
      await fetch("/api/public/event", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          eventName: "PageView",
          eventData: { page_path: pathname, fbclid },
        }),
      });
    }
  }, [pathname, searchParams, user?.data.optOut]);

  useEffect(() => {
    trackEvent();
  }, [pathname, searchParams, trackEvent]);

  return null;
};

export default EventTracker;
